<p-overlayPanel #op [style]="{ width: 'content-max', minWidth: '24rem', padding: '0' }">
  <ng-template pTemplate="content">
    <ul class="m-0 p-0">
      @for (item of control.value; track trackBy(i); let i = $index) {
        <li class="d-flex align-items-center justify-content-between mt-3">
          <div class="position-relative w-100">
            <p-dropdown
              [(ngModel)]="control.value[i]"
              [options]="config().options?.items"
              [optionLabel]="options.bindLabel"
              [optionValue]="options.bindValue"
              [virtualScroll]="false"
              [virtualScrollItemSize]="24.5"
              [overlayOptions]="{ autoZIndex: true, baseZIndex: 1100, appendTo: 'body' }"
              [autoDisplayFirst]="false"
            >
            </p-dropdown>
          </div>
          <button class="btn btn-danger btn-sm w-auto ms-3" type="button" (click)="$event.stopPropagation(); removeRow(i)">
            <i class="fa fa-trash"></i>
          </button>
        </li>
      }
    </ul>
    <div class="row mx-1 mt-3 align-items-center">
      <div class="col-6">
        @if (!config().readonly) {
          <button class="btn btn-secondary w-100" type="button" (click)="addRow()">Add</button>
        }
      </div>
      <div class="col-6">
        <button class="btn btn-primary w-100" type="button" (click)="op.hide()" autofocus>OK</button>
      </div>
    </div>
  </ng-template>
</p-overlayPanel>
<div class="dynamic-field form-input w-100" [class.ui-float-label]="config().labelType === 'float'">
  <label class="active"
    >{{ config().label }}
    @if (config().required) {
      <span>*</span>
    }
  </label>
  <div class="d-flex w-100 align-items-center justify-content-between">
    <p class="form-control text-truncate w-100" (click)="!config().disabled && op.toggle($event)">
      @if (control.value.length > 1) {
        @for (item of control.value; track item; let first = $first; let last = $last) {
          <span [ngClass]="{ 'border-start': !first, 'me-2': !last, 'ps-2': !first }">{{ item }}</span>
        }
      } @else {
        <span>{{ getOptionLabel(control.value[0]) }}</span>
      }
      <span>&nbsp;</span>
    </p>
  </div>
</div>
<lib-shared-ui-form-errors [control]="control" [label]="config().label ?? ''"></lib-shared-ui-form-errors>
